.home-claim {
    text-align: center;
    color: white;
}
.home-claim h2 {
    font-size: 3rem;    
    color: white!important;
}
.home-claim h3 {
    font-size: 1.7rem;
    line-height: 6rem;
}