.home .featured-specialties{
    margin-top: -120px;
}

.featured-professional .featured-professional-card,
.featured-specialties .featured-specialties-card {
    width: 20%;
    float: left;
    overflow: hidden;
}

.featured-professional button,
.featured-specialties button,
.featured-professional .disponibilidad {
    background-color: #1380BF;
    border: none;
    padding: 12px 25px;
    float: right;
    position: absolute;
    font-size: 18px;
    right: 8px;
    top: 38px;
    width: 220px;
    height: auto;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
}

.featured-professional .disponibilidad {
    position: relative;
    width: 100%;
    height: auto;
    right: auto;
    top: auto;
    text-align: center;
    color: #FFF;
    font-size: 16px;
    margin-top: 10px;
}
.featured-professional button:hover,
.featured-specialties button:hover {
    background-color: rgb(8, 94, 145);    
}

.featured-professional .see-all,
.featured-specialties .see-all {
    color:white;
}
.featured-professional .see-all:hover,
.featured-specialties .see-all:hover {
    color:white;
    text-decoration:none;
}

.featured-professional .see-more {
    color: #226eb7;
    font-weight: 400;
    font-size: 1.2em;
    float: left;
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 0px 40px 70px 12%;
    text-decoration: none;
}
.featured-professional .see-more:hover span{
    text-decoration:underline;
    cursor:pointer;
}

.featured-professional .see-more span {
    display: block;
    overflow: hidden;
    float: left;
    width: auto;
    margin-right: 25px;
    font-weight: 500;
}

.featured-professional .see-more em{
    font-weight: 500;
}