.form-profile {
    padding: 0 198px 0 185px;    
    margin-bottom: 6rem;
}
.form-profile input[type="text"], input[type="password"]  {
    padding: 10px 20px;
    height: 50px;
}

#gender {
    padding: 10px 20px;
    height: 50px;
}


.form-profile .formProfile-container {
    height: 100%;
    padding: -30px 41px;
    background-color: white;
}
.react-datepicker-wrapper, .react-datepicker__input-container, .signin > form > div > div:nth-child(7) > div > div:nth-child(2) > div.react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
    max-width: none;
}
.form-profile #fechaNac {
    margin: 0 auto;
    width: 100%;
}


.formProfile-container{
    border-radius: 25px;   
    align-content:center;
}

.form-profile .formProfile-row {
    padding:30px 51px 0px 43px;
}

.form-profile .formProfile-check {
    margin-left: 30px;
    padding:46px 51px 0px 43px;
}

.form-profile .formProfile-Button{
    padding:10px 94px 10px 89px;
    margin-bottom: 25px;
    float: right;
    background-color: #1380BF;
    border: none;
    font-size: 18px;
    top: 38px;
    height: auto;
    color: white;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));

}

.form-profile .formProfile-col{
    padding:0px 37px 0px 39px;

}
