.nav-login nav div ul li {
    margin-left:20px;
    margin-right:20px;  
}
.nav-login nav div ul li a {
    font-weight: bolder;
    color: white;
    line-height: 100px;    
    margin-right: -25px;
}
.nav-login nav div ul li button {
    font-weight: bolder;
    background-color: white;
    color: #0F9CCC;
    border-radius:20px;
    margin-top:31px;
    border: 0;
}
.nav-login nav div ul li button.out {
    width:160px;
}
.nav-login nav div ul li button.badget {
    width:30px;
}
.nav-login nav div ul li button.badget-pro {
    width:30px;
    background-color: greenyellow;
}
.nav-login nav div ul li button:hover {
    background-color: #a0dfef;
    
}
.nav-login nav div ul li button a{
    color: #0F9CCC;
    font-weight: bold;    
    line-height: 0px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;    
    margin-right: 0px;
}
.nav-login nav ul li a:hover{
    text-decoration: none;
}