body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  background-image: url(utils/svg/Header.svg);
  background-position: 50% -200px;
  background-size: 100%;
  background-repeat-y: no-repeat;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", "Courier New" !important;
}
h2{
  margin: 50px 0 40px 0 !important;
  text-align: center;
  font-size: 2.4em !important;
  color: #545454 !important;
  font-weight: 400 !important;
  
}

.form-template {
  background-color: white;
  width: 40%;
  max-width: 720px;
  margin: 0 auto;
  padding: 68px 35px 0px;
  box-shadow: 0px 0px 8px 0px #a9a9a9;
  border: 1px solid grey;
  border-radius: 10px;
  font-family: "Poppins";
  font-size: 15px;
}

.form-template .form-fields {
  width: 70%;
  margin: 0 auto;
}

.form-template input[type="text"], input[type="password"]{
  width: 100%;
  height: 50px;
}


.main-content h3 {
  font-size: 1.5em;
}

.main-content .txt {
  padding-bottom: 40px;
}

.main-content.form-template {
  padding: 70px 0 30px 0;
  margin-bottom: 200px;
}

.form-template label {
  display: inline-block;
  margin: 20px 0px 14px;
}

.color-white{
  color: #FFF !important;
}

.shadow{
  -moz-box-shadow: 0px 0px 35px 1px #54545473;
  -webkit-box-shadow: 0px 0px 35px 1px #54545473;
  box-shadow: 0px 0px 35px 1px #54545473;
}

h2.color-white{
  font-weight: normal !important;
}

code {
  font-family: "Poppins", "Courier New", monospace;
}

.main{
  margin: 0 auto;
}

.main-content:not(.home) {
  padding-top: 70px;
}

.main-content:not(.home)>.row {
  background: #FFF;
}

.main-content:not(.home) .main-search {
  margin: -30px -15px 0 -15px;
  width: calc(100% + 30px);
  max-width: none;
  box-shadow: none;
}

.main-content:not(.home) .main-search.shadow{
  -moz-box-shadow: 0px 13px 16px 0px #efefef;
  -webkit-box-shadow: 0px 13px 16px 0px #efefef;
  box-shadow: 0px 13px 16px 0px #efefef;
}

.main-content>.row {
    margin: 0;
}

.main-content-center {
  margin: 0 auto;
  max-width: 1520px;
}

.navbar {
  padding: 1.5rem 1rem !important;
}

.header,
.main-search,
.featured-professional,
.featured-specialties,
.proposal-claim,
.more-claim,
.comments-claim,
.footer {
    overflow: hidden;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
}

.final-content {
  min-height: 15px;
  background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#1090c6), to(#250f8a));
}

