.comment-card {
    background-color: rgb(255,255,255,0.2);
    margin: 10px;
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: calc((100% - 60px)/3);
}
.comment-card img {
    width:100%;
}
.comment-card .comment{
    text-align:center;
    padding-left:30px;
    padding-right:30px;
}
.comment-card .mes{
    text-transform: capitalize;
}

.comment-card em{
    margin: 18px auto;
}
.comment-card .comment p {
    width:100%;
    font-size:4rem;
    margin-bottom:-2.3rem;
}
.comment-card .userinfo p{
    margin-bottom:0px;
}
.comment-card .empresa{
    opacity: 0.5;
}
.comment-card .marco{
    border-radius:60px;
    overflow:hidden;
    width: 60px;
    height: 60px;
    display: inline-flex;
    float: right;
}
.comment-card .centraCircle{
    display: block;
    text-align: center;
}
.comment-card .description{
    font-size: 16px;
    width: 100%;
}
