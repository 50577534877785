.more-claim{
    margin-top:5%;
    padding-left:40px;
    margin-bottom:5%;
}
.more-claim .claim h2{
    text-align: left;
}
.more-claim .claim{
    margin-top:10%;
    margin-left:3%;    
}
.more-claim ul{
    list-style: none;
    padding:0px;
    margin-top:1rem;
}
.more-claim li button{ 
    width:250px;
    border-radius:5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    color:black;
    background-color:transparent; 
    border:none; 
    opacity: 0.5;
    font-weight:600;
}
.more-claim li button:hover{
    box-shadow:1px 1px 1px 1px gray;
    background-color:transparent;
    color: black;
    opacity:1;
} 
.more-claim li svg {
    color: #1861B0;
    margin-right: 1rem;
}
.more-claim .img {
    text-align: right;
}