.footer-cont{
    background-color:white;
    color:Black;
    font-weight: 470;
}

.footer-cont .footer {
    padding: 22px 22px;
    font-size: 1.1em;
}

.footer-cont .footer a{
    color: black!important;
    padding-right:30px;
}
.footer-cont .footer a:hover{
    text-decoration: underline;
}
.footer-cont .footer .copyright{
    text-align:right;
    padding-right:10%;
}
.footer-cont .footer .enlaces{
    padding-left:10%;
}