.main-content.home>.row:first-of-type {
    padding-bottom: 200px;
    background: none;
}

.main-content.home>.row:nth-child(3){
    background-image: url(../../utils/svg/Background.svg);
    background-size: 100%;
}

.main-content.home>.row:first-of-type h2{
    font-size: 3em !important;
}
@media (max-width: 991px){
    .main-content .professional-card, .main-content .comment-card, .main-content .specialty-card{
        width:50%;
        margin: 0 0 40px
    }
    .main-content .featured-professional h2, .main-content .comment-card h2, .main-content .specialty-card h2{
        margin-top: 50px!important;
    }
    .main-content .featured-professional h2{
        margin-bottom: 80px!important;
    }
    .main-content .featured-professional button{
        top: 105px;
        right: 0;
    }
}
@media (max-width: 575px){
    .main-content .professional-card, .main-content .comment-card, .main-content .specialty-card{
        width:100%;
    }
    .main-content .featured-professional button{
        top: 155px;
        right: 0;
    }
}
