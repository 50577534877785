.professional-profile .psinfo{
    padding: 0% 10%
}
.professional-profile.professional>.row {
    background: #FFF;
}

.professional-profile.professional>.row:first-of-type {
    background: none;
    padding-bottom: 200px;
}

.professional-profile.professional>.row:first-of-type h2{
    font-size: 3em !important;
}
.professional-profile button.cita{
    background-color: #1380BF;
    border: none;
    padding: 12px 25px;
    font-size: 18px;
    right: 8px;
    top: 38px;
    width: 100%;
    height: auto;
    color:white;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
}
.professional-profile button{
    background-color:transparent;
    border: solid;
    padding: 6px 12px;
    font-size: 18px;
    right: 8px;
    top: 38px;
    width: 100%;
    color: #D3D3D3;
    height: auto;
}
.professional-profile button.saved svg{
    color:#CB5B5B;
}

.professional-profile button.save, .professional-profile button.saved {
    width: calc(40%)
}
.professional-profile button.check{
    width: calc(60% - 5px);
    margin-right:5px;
}
.professional-profile button.checkea{
    width: 100%;
}

.professional-profile button:hover{
    background-color:transparent;    
    color: #D3D3D3;
    border-color: #d3d3d3;
    height: auto;
}
.professional-profile button.cita:hover{    
    color:white;
    
}
.professional-profile .actions button{
    margin-top:30px;
}

.professional-profile h2{
    font-weight:400!important;
    text-align: left;
    margin:10px!important;
    padding: 20px 0px 0px;
}

.professional-profile .datos h3 {
    font-weight: 400!important;
    margin-top: 6px;
    margin-bottom: 20px;
    font-family: arial !important;
    font-size: 1.5em;
}
.professional-profile .datos .biography {
    border: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: #d3d3d3;
    padding: 20px;
    line-height: 26px;
    font-size: 1.1em;
}
.professional-profile hr {
    border-width: 3px;
    margin: 25px 0;
}
.professional-profile .psinfo .datos button {
    border-width: 1px;
    margin-top: 30px;
    font-size: 1.1em;
    width: auto;
    padding: 10px 23px;
    color: #25292d;
    font-weight: 500;
    border: 1px solid #d3d3d3;
}

.professional-profile .media-data {
    text-align: center;
    width: 100%;
    margin-top: -26px;
    margin-left: -15px;
    margin-right: -15px;
    max-height: 540px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% + 30px);
}
.main-search .input-group {
    z-index:1;
}
.professional-profile .media-data .imgProfile{
    width: 100%;
    max-height:500px;
}
.professional-profile .media-data .imgProfile img{    
    width: 100%;
    height: auto;
    margin-top: -25%;
}
.professional-profile .media-data{
    position: relative;
}

.professional-profile .media-data video {
    width: 100%;
}
.professional-profile .media-data .videocontrols{
    position: absolute;
    bottom: 0; 
}
.professional-profile .media-data .video-react-big-play-button{
    top: 50%;
    margin: 0 auto;
}
.professional-profile .media-data .video-react{
    position:initial;
    display:block;
    text-align:center;
}
.professional-profile .comments-claim-cont{
    background:none;
    color:black;
    border-bottom: 1px, solid;
}
.professional-profile .comments-claim{
    padding:0;
}
.professional-profile .comments-claim-cont h2 {
    color:black!important;
}
.professional-profile .comment-card {
    width: 100%;
    padding: 0px;
}
.professional-profile .comment-card em{
    display:none;
}
.professional-profile .comment-card span {
    text-align:left;
}
.professional-profile .comment-card .username{
    font-weight:bolder;
}
.professional-profile .comment-card .empresa{
    font-weight:500;
    color: black;
}
.professional-profile .read-more-button{
    color:#34ABD4;
}
.professional-profile .professional-card{
    width:25%;
}
.professional-profile .professional-card .info{
    background:white;
    z-index: 1;
    position: relative;
}
.professional-profile .featured-professional{
    padding-left:7%;
    padding-right:7%;
}
.professional-profile .featured-professional h2.title,.professional-profile .featured-professional h3.subtitle{
    text-align:center;
    padding: 0% 10%;
    font-weight:400;
}
.professional-profile .backg {
    background: -webkit-gradient(linear, 76% 52%, 8% 30%, from(#04E2ED), to(#24178D));
    height:200px;
    margin-top: -200px;
}
.professional-profile .professional-card .image{
    box-shadow:0px 0px 11px 4px #e0e0e0;
}

.modal .modal-dialog.modalCom,  .modal .modal-dialog.selectorHora{
    max-width: 960px;
    margin: 7rem auto;
    text-align: center;
}
.modalCom .modal-content, .selectorHora .modal-content {
    padding:6rem;  
    padding-top:3rem;  
    border-radius: 15px;
}
.modalCom .modal-content h3, .selectorHora .modal-content h3{
    font-weight: 400;
    padding-bottom: 2rem;
}
.modalCom .modal-content p{
    padding: 0rem 4rem;
    font-size:1.1rem;
    font-weight:400;
    text-align:left;
}
.horarios .modal-content p.empty{
    text-align:center;
}
.modalCom button, .selectorHora .btn{
    border: none;
    padding: 12px 25px;
    position:relative;
    font-size: 18px;
    bottom: -5rem;
    width: 14rem;
    height: auto;    
}
.modalCom .btn-primary, .selectorHora .btn{
    right: -14rem;
    color: white;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
    background-color: #1380BF;
}
.modalCom .btn-secondary{
    left: -14rem;
}
.selectorHora .notes {
    text-align: left;
}
.preferenciasHora .form-inline{  
    display:initial;
}
@media (max-width: 991px){
    .professional-profile .professional-card{
        width:50%;
    }
    .professional-profile .featured-professional h2{
        margin-top: 50px!important;
    }
}
@media (max-width: 575px){
    .professional-profile .professional-card{
        width:100%;
    }
}