.rating {
    font-weight: 500;
    font-size: 1em;
    width: 100%;
    overflow: hidden;
}

.rating .rating-stars {
    float: left;
    padding: 3px 0;
}
.rating .rating-stars img {
    width: 15px;
    margin-right: 4px;
}

.rating .reviews-cont {
    float: left;
    padding: 5px 4px 5px 0px;
    color: #545454;
    font-weight: 700;
}

.rating.parcial{
    padding: 6px 0;
}

.rating.parcial .name{
    float: left;
}

.rating.parcial .rating-stars {
    float: right;
}
.rating.parcial .rating-stars img{
    width:18px;
}
.rating.parcial .rating-stars {
    padding: 0;
    line-height: 1;
}
.rating.profile{

}

.rating.profile .reviews-cont {
    margin-left: 10px;
    padding: 8px 4px;
    font-size: 0.8em;
}
.rating.profile .rating-stars{
    
}

.rating.profile .rating-stars img {
    width: 25px;
    margin-right: 4px;
}