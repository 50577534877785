.main-search form {
    margin: 0 auto;
    max-width: 800px;
}

.main-search form .input-group [class^=col] {
    padding: 0;
}

.main-search form select, .main-search form input {
    padding: 15px 18px;
    height: auto;
    font-weight: 500;
}
.main-search form input{
    background-image: url(/img/ico/search.png);
    background-repeat: no-repeat;
    background-size: 8%; 
    background-position: calc(100% - 10px) 50%;
    padding-right: 45px;
}

.main-search form select, .main-search form input, .main-search .form-control:focus{
    color: #929292;
}

.main-search form select{
    background-image: linear-gradient(60deg, transparent 50%, #313131 50%), linear-gradient(118deg, #313131 50%, transparent 50%), linear-gradient(to right, #ffffff, white);
    background-position: calc(100% - 25px) calc(1em + 5px), calc(100% - 18px) calc(1em + 5px), 100% 0;
    background-size: 8px 10px, 8px 10px, 51px 3.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.main-search form .input-group {
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 8px 9px -2px #ececec;
}
.main-search .filtros {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    display: block;
    padding: 30px 10px;
}
.main-search .filtros button {
    border: 2px solid #d3d3d3;
    color: #999999;
    padding: 8px 32px;
    font-size: 1.1em;
    margin: 10px 10px;
    display: inline-block;
}
.main-search .filtros button:hover{
    background-color:rgba(222, 222, 222, 0.3);
}

