.NavMain {
    margin-left: 20px;
    margin-right: 20px;
    display: inline-block;
}
.NavMain a {
    font-weight: bolder;
    color: white;
    opacity: 0.5;    
    line-height: 100px;
}
.NavMain a:hover {
    color: white;
    opacity: 1;
    text-decoration: none;
}
.NavMain a.opaco {
    opacity: 1;
}
div .posnavbar div ul.ulMain {
    display: block;
    width: 100%;
    text-align: center;
}