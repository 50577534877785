.editContainer {
    margin-top: 3rem;
}
.editContainer h2{
    text-align: center;
    color: white!important;
    margin-bottom:4rem;
}
.editContainer .edit-form.col{
    padding:0;
}
.editContainer .NavUser-container .NavUser-item {
    color: lightgray;
    font-weight:400;
}
.editContainer .NavUser-item:hover, .editContainer .NavUser-container .NavUser-item:hover svg{
    color: #1A52A9;
}
.editContainer .NavUser-container{
    background:transparent;
}
.editContainer .edit-form{
    background-color: white;
    border-radius:10px;
    margin-left: 20%;
    margin-right: 20%;
    max-width:1140px;

}
.editContainer .form-profile {
    padding:0;
}
.editContainer .form-profile .formProfile-container{
    box-shadow:none;
    margin:0 auto;
}
.edit-container .editContainer .form-profile, .editContainer .navUser-tabList {
    margin:0;
    width:100%;
    border-left:none;
}
.editContainer .NavUser-container .navUser-tabList li:hover, .editContainer .NavUser-container .navUser-tabList li:hover svg, .editContainer .react-tabs__tab--selected, .editContainer .react-tabs__tab--selected svg{
    color:#1A52A9
}

.editContainer ul{
    border-bottom: solid 1px;
    border-bottom-color: rgb(75, 75, 75);
    padding:0;
    background:transparent;
    border-color:#D1D1D1;
    display: flex;
    flex-direction: row;
    margin-left:-15px;
    margin-right:-15px;
}
.editContainer li{
    border-radius:0;
    border:none;
    border-right: solid 1px;
    background: transparent;
    border-color:#D1D1D1;
    position:relative;
    top:40%;
    padding: 15px;
    display: flex;
    align-items: center;
    line-height: 0.9rem; 
    color:#A8A8A8; 
}
.editContainer li.react-tabs__tab--selected {
    border:none;
    border-right: solid 1px;
    border-color:#D1D1D1;
    font-weight: bold;

}