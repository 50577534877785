.comments-claim-cont{
    background-image: url(../../utils/svg/Header.svg);
    background-size: 100%;
    color: white;
    margin-left: -15px;
    margin-right: -15px;
}

.comments-claim-cont .comments-claim {
    padding: 0.5rem 8% 5rem 8%;    
}

.comments-claim-cont .comments-claim h2{
    color:white !important;
    font-weight: 400 !important;
}
