.professional-card, 
.specialty-card {
    width: 20%;
    float: left;
    margin: 0 0 70px;
    overflow: hidden;
    padding: 8px;
}

.professional-card .image, 
.specialty-card .image {
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 0px #e0e0e0;
    position: relative;
}

.professional-card .image img, 
.specialty-card .image img {
    width: 100%
}

.professional-card .image span {
    position: absolute;
    bottom: 6px;
    left: 6px;
    background: #0000007d;
    color: #FFF;
    font-size: 13px;
    letter-spacing: 0.6px;
    border-radius: 5px;
    padding: 5px 9px;
}

.professional-card .info {
    padding: 0 15px 15px 15px;
}

.professional-card .info h3, 
.specialty-card .info h3 {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    padding: 16px 0px 8px 0;
    height:4.5rem;
    overflow:hidden;
}

.professional-card p{
    color: #a1a1a1;
    height: 140px;
    overflow: hidden;
}

.professional-card .reviews-cont, 
.specialty-card .reviews-cont {
    font-weight: 600;
    float: left;
    display: block;
    margin-right: 35px;
}
.professional-card a, .specialty-card a,.professional-card a:hover, .specialty-card a:hover{
    text-decoration: none;
    color: black;
}