.proposal-claim .tarjeta {
    margin-left: 35px;
    margin-right: 35px;
    text-align: center;
    font-size:0.9rem;
}
.proposal-claim a {
    line-height:50px;
    color: #3279BC;
    font-size: 1.1rem;
    font-weight: 500;    
}
.proposal-claim h3 {
    font-weight: bold;
    font-size: 1.3rem;
    height:2.3rem;
}
.proposal-claim .row {
    margin-left:10%;
    margin-right:10%;
}