.form-template .to-signin {
    text-align: center;
    display: block;
    color: #505356;
    margin: 25px 0px 0px 0px;
}

.form-template .login-page-row{

    padding: 15px;
    margin: 0 auto;
}

.form-template .login-button {
    width: 78%;
    height: auto;
    margin: 15px auto 0 auto;
    display: block;
    padding: 12px;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
}




@media only screen and (max-width: 1400px) {
    .login-page {
        width: 50%;
    }
}

@media only screen and (max-width: 1000px) {
}

