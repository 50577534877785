.NavUser-container{
    margin: 0 auto;
}


.NavUser-container .NavUser-item{ 
    max-height: 123px;
    float:left;
    border-right: 1px solid;
    border-color: grey;
    display: inline-flex;
    font-weight: bold;
    color:#1a57ba;
}

.navUser-tabList{
    /* padding: 0px 0 0 210px; */
    /* background-color: white; */
    margin: 0 auto;
    width: 70%;
    /* border: 1px solid grey;
    border-radius: 25px; */
}


.NavUser-container .navUser-Icons{
    font-size:35px;
    margin-left:5px;
    margin-top:5px;
    margin-right:15px;
    color: grey;
}


.NavUser-container .navUser-Icons:active{
    color:#1a57ba;
}


