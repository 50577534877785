body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  background-image: url(/static/media/Header.4e59128d.svg);
  background-position: 50% -200px;
  background-size: 100%;
  background-repeat-y: no-repeat;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", "Courier New" !important;
}
h2{
  margin: 50px 0 40px 0 !important;
  text-align: center;
  font-size: 2.4em !important;
  color: #545454 !important;
  font-weight: 400 !important;
  
}

.form-template {
  background-color: white;
  width: 40%;
  max-width: 720px;
  margin: 0 auto;
  padding: 68px 35px 0px;
  box-shadow: 0px 0px 8px 0px #a9a9a9;
  border: 1px solid grey;
  border-radius: 10px;
  font-family: "Poppins";
  font-size: 15px;
}

.form-template .form-fields {
  width: 70%;
  margin: 0 auto;
}

.form-template input[type="text"], input[type="password"]{
  width: 100%;
  height: 50px;
}


.main-content h3 {
  font-size: 1.5em;
}

.main-content .txt {
  padding-bottom: 40px;
}

.main-content.form-template {
  padding: 70px 0 30px 0;
  margin-bottom: 200px;
}

.form-template label {
  display: inline-block;
  margin: 20px 0px 14px;
}

.color-white{
  color: #FFF !important;
}

.shadow{
  box-shadow: 0px 0px 35px 1px #54545473;
}

h2.color-white{
  font-weight: normal !important;
}

code {
  font-family: "Poppins", "Courier New", monospace;
}

.main{
  margin: 0 auto;
}

.main-content:not(.home) {
  padding-top: 70px;
}

.main-content:not(.home)>.row {
  background: #FFF;
}

.main-content:not(.home) .main-search {
  margin: -30px -15px 0 -15px;
  width: calc(100% + 30px);
  max-width: none;
  box-shadow: none;
}

.main-content:not(.home) .main-search.shadow{
  box-shadow: 0px 13px 16px 0px #efefef;
}

.main-content>.row {
    margin: 0;
}

.main-content-center {
  margin: 0 auto;
  max-width: 1520px;
}

.navbar {
  padding: 1.5rem 1rem !important;
}

.header,
.main-search,
.featured-professional,
.featured-specialties,
.proposal-claim,
.more-claim,
.comments-claim,
.footer {
    overflow: hidden;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
}

.final-content {
  min-height: 15px;
  background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#1090c6), to(#250f8a));
}


.main-search form {
    margin: 0 auto;
    max-width: 800px;
}

.main-search form .input-group [class^=col] {
    padding: 0;
}

.main-search form select, .main-search form input {
    padding: 15px 18px;
    height: auto;
    font-weight: 500;
}
.main-search form input{
    background-image: url(/img/ico/search.png);
    background-repeat: no-repeat;
    background-size: 8%; 
    background-position: calc(100% - 10px) 50%;
    padding-right: 45px;
}

.main-search form select, .main-search form input, .main-search .form-control:focus{
    color: #929292;
}

.main-search form select{
    background-image: -webkit-linear-gradient(30deg, transparent 50%, #313131 50%), -webkit-linear-gradient(332deg, #313131 50%, transparent 50%), -webkit-linear-gradient(left, #ffffff, white);
    background-image: linear-gradient(60deg, transparent 50%, #313131 50%), linear-gradient(118deg, #313131 50%, transparent 50%), linear-gradient(to right, #ffffff, white);
    background-position: calc(100% - 25px) calc(1em + 5px), calc(100% - 18px) calc(1em + 5px), 100% 0;
    background-size: 8px 10px, 8px 10px, 51px 3.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.main-search form .input-group {
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 8px 9px -2px #ececec;
}
.main-search .filtros {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    display: block;
    padding: 30px 10px;
}
.main-search .filtros button {
    border: 2px solid #d3d3d3;
    color: #999999;
    padding: 8px 32px;
    font-size: 1.1em;
    margin: 10px 10px;
    display: inline-block;
}
.main-search .filtros button:hover{
    background-color:rgba(222, 222, 222, 0.3);
}


.comments-claim-cont{
    background-image: url(/static/media/Header.4e59128d.svg);
    background-size: 100%;
    color: white;
    margin-left: -15px;
    margin-right: -15px;
}

.comments-claim-cont .comments-claim {
    padding: 0.5rem 8% 5rem 8%;    
}

.comments-claim-cont .comments-claim h2{
    color:white !important;
    font-weight: 400 !important;
}

.professional-card, 
.specialty-card {
    width: 20%;
    float: left;
    margin: 0 0 70px;
    overflow: hidden;
    padding: 8px;
}

.professional-card .image, 
.specialty-card .image {
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 0px #e0e0e0;
    position: relative;
}

.professional-card .image img, 
.specialty-card .image img {
    width: 100%
}

.professional-card .image span {
    position: absolute;
    bottom: 6px;
    left: 6px;
    background: #0000007d;
    color: #FFF;
    font-size: 13px;
    letter-spacing: 0.6px;
    border-radius: 5px;
    padding: 5px 9px;
}

.professional-card .info {
    padding: 0 15px 15px 15px;
}

.professional-card .info h3, 
.specialty-card .info h3 {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    padding: 16px 0px 8px 0;
    height:4.5rem;
    overflow:hidden;
}

.professional-card p{
    color: #a1a1a1;
    height: 140px;
    overflow: hidden;
}

.professional-card .reviews-cont, 
.specialty-card .reviews-cont {
    font-weight: 600;
    float: left;
    display: block;
    margin-right: 35px;
}
.professional-card a, .specialty-card a,.professional-card a:hover, .specialty-card a:hover{
    text-decoration: none;
    color: black;
}
.comment-card {
    background-color: rgb(255,255,255,0.2);
    margin: 10px;
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: calc((100% - 60px)/3);
}
.comment-card img {
    width:100%;
}
.comment-card .comment{
    text-align:center;
    padding-left:30px;
    padding-right:30px;
}
.comment-card .mes{
    text-transform: capitalize;
}

.comment-card em{
    margin: 18px auto;
}
.comment-card .comment p {
    width:100%;
    font-size:4rem;
    margin-bottom:-2.3rem;
}
.comment-card .userinfo p{
    margin-bottom:0px;
}
.comment-card .empresa{
    opacity: 0.5;
}
.comment-card .marco{
    border-radius:60px;
    overflow:hidden;
    width: 60px;
    height: 60px;
    display: inline-flex;
    float: right;
}
.comment-card .centraCircle{
    display: block;
    text-align: center;
}
.comment-card .description{
    font-size: 16px;
    width: 100%;
}

.proposal-claim .tarjeta {
    margin-left: 35px;
    margin-right: 35px;
    text-align: center;
    font-size:0.9rem;
}
.proposal-claim a {
    line-height:50px;
    color: #3279BC;
    font-size: 1.1rem;
    font-weight: 500;    
}
.proposal-claim h3 {
    font-weight: bold;
    font-size: 1.3rem;
    height:2.3rem;
}
.proposal-claim .row {
    margin-left:10%;
    margin-right:10%;
}
.more-claim{
    margin-top:5%;
    padding-left:40px;
    margin-bottom:5%;
}
.more-claim .claim h2{
    text-align: left;
}
.more-claim .claim{
    margin-top:10%;
    margin-left:3%;    
}
.more-claim ul{
    list-style: none;
    padding:0px;
    margin-top:1rem;
}
.more-claim li button{ 
    width:250px;
    border-radius:5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    color:black;
    background-color:transparent; 
    border:none; 
    opacity: 0.5;
    font-weight:600;
}
.more-claim li button:hover{
    box-shadow:1px 1px 1px 1px gray;
    background-color:transparent;
    color: black;
    opacity:1;
} 
.more-claim li svg {
    color: #1861B0;
    margin-right: 1rem;
}
.more-claim .img {
    text-align: right;
}
.home-claim {
    text-align: center;
    color: white;
}
.home-claim h2 {
    font-size: 3rem;    
    color: white!important;
}
.home-claim h3 {
    font-size: 1.7rem;
    line-height: 6rem;
}
.home .featured-specialties{
    margin-top: -120px;
}

.featured-professional .featured-professional-card,
.featured-specialties .featured-specialties-card {
    width: 20%;
    float: left;
    overflow: hidden;
}

.featured-professional button,
.featured-specialties button,
.featured-professional .disponibilidad {
    background-color: #1380BF;
    border: none;
    padding: 12px 25px;
    float: right;
    position: absolute;
    font-size: 18px;
    right: 8px;
    top: 38px;
    width: 220px;
    height: auto;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
}

.featured-professional .disponibilidad {
    position: relative;
    width: 100%;
    height: auto;
    right: auto;
    top: auto;
    text-align: center;
    color: #FFF;
    font-size: 16px;
    margin-top: 10px;
}
.featured-professional button:hover,
.featured-specialties button:hover {
    background-color: rgb(8, 94, 145);    
}

.featured-professional .see-all,
.featured-specialties .see-all {
    color:white;
}
.featured-professional .see-all:hover,
.featured-specialties .see-all:hover {
    color:white;
    text-decoration:none;
}

.featured-professional .see-more {
    color: #226eb7;
    font-weight: 400;
    font-size: 1.2em;
    float: left;
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 0px 40px 70px 12%;
    text-decoration: none;
}
.featured-professional .see-more:hover span{
    text-decoration:underline;
    cursor:pointer;
}

.featured-professional .see-more span {
    display: block;
    overflow: hidden;
    float: left;
    width: auto;
    margin-right: 25px;
    font-weight: 500;
}

.featured-professional .see-more em{
    font-weight: 500;
}
.rating {
    font-weight: 500;
    font-size: 1em;
    width: 100%;
    overflow: hidden;
}

.rating .rating-stars {
    float: left;
    padding: 3px 0;
}
.rating .rating-stars img {
    width: 15px;
    margin-right: 4px;
}

.rating .reviews-cont {
    float: left;
    padding: 5px 4px 5px 0px;
    color: #545454;
    font-weight: 700;
}

.rating.parcial{
    padding: 6px 0;
}

.rating.parcial .name{
    float: left;
}

.rating.parcial .rating-stars {
    float: right;
}
.rating.parcial .rating-stars img{
    width:18px;
}
.rating.parcial .rating-stars {
    padding: 0;
    line-height: 1;
}
.rating.profile{

}

.rating.profile .reviews-cont {
    margin-left: 10px;
    padding: 8px 4px;
    font-size: 0.8em;
}
.rating.profile .rating-stars{
    
}

.rating.profile .rating-stars img {
    width: 25px;
    margin-right: 4px;
}
.main-content.home>.row:first-of-type {
    padding-bottom: 200px;
    background: none;
}

.main-content.home>.row:nth-child(3){
    background-image: url(/static/media/Background.37409343.svg);
    background-size: 100%;
}

.main-content.home>.row:first-of-type h2{
    font-size: 3em !important;
}
@media (max-width: 991px){
    .main-content .professional-card, .main-content .comment-card, .main-content .specialty-card{
        width:50%;
        margin: 0 0 40px
    }
    .main-content .featured-professional h2, .main-content .comment-card h2, .main-content .specialty-card h2{
        margin-top: 50px!important;
    }
    .main-content .featured-professional h2{
        margin-bottom: 80px!important;
    }
    .main-content .featured-professional button{
        top: 105px;
        right: 0;
    }
}
@media (max-width: 575px){
    .main-content .professional-card, .main-content .comment-card, .main-content .specialty-card{
        width:100%;
    }
    .main-content .featured-professional button{
        top: 155px;
        right: 0;
    }
}

.logo {
    overflow: hidden;
    padding: 16px 30px;
}
.logo img {
    width: 100%;
}


.nav-login nav div ul li {
    margin-left:20px;
    margin-right:20px;  
}
.nav-login nav div ul li a {
    font-weight: bolder;
    color: white;
    line-height: 100px;    
    margin-right: -25px;
}
.nav-login nav div ul li button {
    font-weight: bolder;
    background-color: white;
    color: #0F9CCC;
    border-radius:20px;
    margin-top:31px;
    border: 0;
}
.nav-login nav div ul li button.out {
    width:160px;
}
.nav-login nav div ul li button.badget {
    width:30px;
}
.nav-login nav div ul li button.badget-pro {
    width:30px;
    background-color: greenyellow;
}
.nav-login nav div ul li button:hover {
    background-color: #a0dfef;
    
}
.nav-login nav div ul li button a{
    color: #0F9CCC;
    font-weight: bold;    
    line-height: 0px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;    
    margin-right: 0px;
}
.nav-login nav ul li a:hover{
    text-decoration: none;
}
.NavMain {
    margin-left: 20px;
    margin-right: 20px;
    display: inline-block;
}
.NavMain a {
    font-weight: bolder;
    color: white;
    opacity: 0.5;    
    line-height: 100px;
}
.NavMain a:hover {
    color: white;
    opacity: 1;
    text-decoration: none;
}
.NavMain a.opaco {
    opacity: 1;
}
div .posnavbar div ul.ulMain {
    display: block;
    width: 100%;
    text-align: center;
}

.footer-cont{
    background-color:white;
    color:Black;
    font-weight: 470;
}

.footer-cont .footer {
    padding: 22px 22px;
    font-size: 1.1em;
}

.footer-cont .footer a{
    color: black!important;
    padding-right:30px;
}
.footer-cont .footer a:hover{
    text-decoration: underline;
}
.footer-cont .footer .copyright{
    text-align:right;
    padding-right:10%;
}
.footer-cont .footer .enlaces{
    padding-left:10%;
}
.form-template .to-signin {
    text-align: center;
    display: block;
    color: #505356;
    margin: 25px 0px 0px 0px;
}

.form-template .login-page-row{

    padding: 15px;
    margin: 0 auto;
}

.form-template .login-button {
    width: 78%;
    height: auto;
    margin: 15px auto 0 auto;
    display: block;
    padding: 12px;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
}




@media only screen and (max-width: 1400px) {
    .login-page {
        width: 50%;
    }
}

@media only screen and (max-width: 1000px) {
}


.welcome-container{
    border: 1px solid black;
    border-radius: 25px;   
}
.directory .professional-card .info h3{
    height:4.5rem;
    overflow:hidden;
}
.form-profile {
    padding: 0 198px 0 185px;    
    margin-bottom: 6rem;
}
.form-profile input[type="text"], input[type="password"]  {
    padding: 10px 20px;
    height: 50px;
}

#gender {
    padding: 10px 20px;
    height: 50px;
}


.form-profile .formProfile-container {
    height: 100%;
    padding: -30px 41px;
    background-color: white;
}
.react-datepicker-wrapper, .react-datepicker__input-container, .signin > form > div > div:nth-child(7) > div > div:nth-child(2) > div.react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
    max-width: none;
}
.form-profile #fechaNac {
    margin: 0 auto;
    width: 100%;
}


.formProfile-container{
    border-radius: 25px;   
    align-content:center;
}

.form-profile .formProfile-row {
    padding:30px 51px 0px 43px;
}

.form-profile .formProfile-check {
    margin-left: 30px;
    padding:46px 51px 0px 43px;
}

.form-profile .formProfile-Button{
    padding:10px 94px 10px 89px;
    margin-bottom: 25px;
    float: right;
    background-color: #1380BF;
    border: none;
    font-size: 18px;
    top: 38px;
    height: auto;
    color: white;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));

}

.form-profile .formProfile-col{
    padding:0px 37px 0px 39px;

}

.NavUser-container{
    margin: 0 auto;
}


.NavUser-container .NavUser-item{ 
    max-height: 123px;
    float:left;
    border-right: 1px solid;
    border-color: grey;
    display: inline-flex;
    font-weight: bold;
    color:#1a57ba;
}

.navUser-tabList{
    /* padding: 0px 0 0 210px; */
    /* background-color: white; */
    margin: 0 auto;
    width: 70%;
    /* border: 1px solid grey;
    border-radius: 25px; */
}


.NavUser-container .navUser-Icons{
    font-size:35px;
    margin-left:5px;
    margin-top:5px;
    margin-right:15px;
    color: grey;
}


.NavUser-container .navUser-Icons:active{
    color:#1a57ba;
}



.editContainer {
    margin-top: 3rem;
}
.editContainer h2{
    text-align: center;
    color: white!important;
    margin-bottom:4rem;
}
.editContainer .edit-form.col{
    padding:0;
}
.editContainer .NavUser-container .NavUser-item {
    color: lightgray;
    font-weight:400;
}
.editContainer .NavUser-item:hover, .editContainer .NavUser-container .NavUser-item:hover svg{
    color: #1A52A9;
}
.editContainer .NavUser-container{
    background:transparent;
}
.editContainer .edit-form{
    background-color: white;
    border-radius:10px;
    margin-left: 20%;
    margin-right: 20%;
    max-width:1140px;

}
.editContainer .form-profile {
    padding:0;
}
.editContainer .form-profile .formProfile-container{
    box-shadow:none;
    margin:0 auto;
}
.edit-container .editContainer .form-profile, .editContainer .navUser-tabList {
    margin:0;
    width:100%;
    border-left:none;
}
.editContainer .NavUser-container .navUser-tabList li:hover, .editContainer .NavUser-container .navUser-tabList li:hover svg, .editContainer .react-tabs__tab--selected, .editContainer .react-tabs__tab--selected svg{
    color:#1A52A9
}

.editContainer ul{
    border-bottom: solid 1px;
    border-bottom-color: rgb(75, 75, 75);
    padding:0;
    background:transparent;
    border-color:#D1D1D1;
    display: flex;
    flex-direction: row;
    margin-left:-15px;
    margin-right:-15px;
}
.editContainer li{
    border-radius:0;
    border:none;
    border-right: solid 1px;
    background: transparent;
    border-color:#D1D1D1;
    position:relative;
    top:40%;
    padding: 15px;
    display: flex;
    align-items: center;
    line-height: 0.9rem; 
    color:#A8A8A8; 
}
.editContainer li.react-tabs__tab--selected {
    border:none;
    border-right: solid 1px;
    border-color:#D1D1D1;
    font-weight: bold;

}
.signin {
    margin-top: 3rem;
}
.signin h2{
    text-align: center;
    color: white!important;
    margin-bottom:4rem;
}
.professional-profile .psinfo{
    padding: 0% 10%
}
.professional-profile.professional>.row {
    background: #FFF;
}

.professional-profile.professional>.row:first-of-type {
    background: none;
    padding-bottom: 200px;
}

.professional-profile.professional>.row:first-of-type h2{
    font-size: 3em !important;
}
.professional-profile button.cita{
    background-color: #1380BF;
    border: none;
    padding: 12px 25px;
    font-size: 18px;
    right: 8px;
    top: 38px;
    width: 100%;
    height: auto;
    color:white;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
}
.professional-profile button{
    background-color:transparent;
    border: solid;
    padding: 6px 12px;
    font-size: 18px;
    right: 8px;
    top: 38px;
    width: 100%;
    color: #D3D3D3;
    height: auto;
}
.professional-profile button.saved svg{
    color:#CB5B5B;
}

.professional-profile button.save, .professional-profile button.saved {
    width: calc(40%)
}
.professional-profile button.check{
    width: calc(60% - 5px);
    margin-right:5px;
}
.professional-profile button.checkea{
    width: 100%;
}

.professional-profile button:hover{
    background-color:transparent;    
    color: #D3D3D3;
    border-color: #d3d3d3;
    height: auto;
}
.professional-profile button.cita:hover{    
    color:white;
    
}
.professional-profile .actions button{
    margin-top:30px;
}

.professional-profile h2{
    font-weight:400!important;
    text-align: left;
    margin:10px!important;
    padding: 20px 0px 0px;
}

.professional-profile .datos h3 {
    font-weight: 400!important;
    margin-top: 6px;
    margin-bottom: 20px;
    font-family: arial !important;
    font-size: 1.5em;
}
.professional-profile .datos .biography {
    border: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: #d3d3d3;
    padding: 20px;
    line-height: 26px;
    font-size: 1.1em;
}
.professional-profile hr {
    border-width: 3px;
    margin: 25px 0;
}
.professional-profile .psinfo .datos button {
    border-width: 1px;
    margin-top: 30px;
    font-size: 1.1em;
    width: auto;
    padding: 10px 23px;
    color: #25292d;
    font-weight: 500;
    border: 1px solid #d3d3d3;
}

.professional-profile .media-data {
    text-align: center;
    width: 100%;
    margin-top: -26px;
    margin-left: -15px;
    margin-right: -15px;
    max-height: 540px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% + 30px);
}
.main-search .input-group {
    z-index:1;
}
.professional-profile .media-data .imgProfile{
    width: 100%;
    max-height:500px;
}
.professional-profile .media-data .imgProfile img{    
    width: 100%;
    height: auto;
    margin-top: -25%;
}
.professional-profile .media-data{
    position: relative;
}

.professional-profile .media-data video {
    width: 100%;
}
.professional-profile .media-data .videocontrols{
    position: absolute;
    bottom: 0; 
}
.professional-profile .media-data .video-react-big-play-button{
    top: 50%;
    margin: 0 auto;
}
.professional-profile .media-data .video-react{
    position:static;
    position:initial;
    display:block;
    text-align:center;
}
.professional-profile .comments-claim-cont{
    background:none;
    color:black;
    border-bottom: 1px, solid;
}
.professional-profile .comments-claim{
    padding:0;
}
.professional-profile .comments-claim-cont h2 {
    color:black!important;
}
.professional-profile .comment-card {
    width: 100%;
    padding: 0px;
}
.professional-profile .comment-card em{
    display:none;
}
.professional-profile .comment-card span {
    text-align:left;
}
.professional-profile .comment-card .username{
    font-weight:bolder;
}
.professional-profile .comment-card .empresa{
    font-weight:500;
    color: black;
}
.professional-profile .read-more-button{
    color:#34ABD4;
}
.professional-profile .professional-card{
    width:25%;
}
.professional-profile .professional-card .info{
    background:white;
    z-index: 1;
    position: relative;
}
.professional-profile .featured-professional{
    padding-left:7%;
    padding-right:7%;
}
.professional-profile .featured-professional h2.title,.professional-profile .featured-professional h3.subtitle{
    text-align:center;
    padding: 0% 10%;
    font-weight:400;
}
.professional-profile .backg {
    background: -webkit-gradient(linear, 76% 52%, 8% 30%, from(#04E2ED), to(#24178D));
    height:200px;
    margin-top: -200px;
}
.professional-profile .professional-card .image{
    box-shadow:0px 0px 11px 4px #e0e0e0;
}

.modal .modal-dialog.modalCom,  .modal .modal-dialog.selectorHora{
    max-width: 960px;
    margin: 7rem auto;
    text-align: center;
}
.modalCom .modal-content, .selectorHora .modal-content {
    padding:6rem;  
    padding-top:3rem;  
    border-radius: 15px;
}
.modalCom .modal-content h3, .selectorHora .modal-content h3{
    font-weight: 400;
    padding-bottom: 2rem;
}
.modalCom .modal-content p{
    padding: 0rem 4rem;
    font-size:1.1rem;
    font-weight:400;
    text-align:left;
}
.horarios .modal-content p.empty{
    text-align:center;
}
.modalCom button, .selectorHora .btn{
    border: none;
    padding: 12px 25px;
    position:relative;
    font-size: 18px;
    bottom: -5rem;
    width: 14rem;
    height: auto;    
}
.modalCom .btn-primary, .selectorHora .btn{
    right: -14rem;
    color: white;
    background: -webkit-gradient(linear, 80% 20%, 10% 21%, from(#0caad2), to(#137dbd));
    background-color: #1380BF;
}
.modalCom .btn-secondary{
    left: -14rem;
}
.selectorHora .notes {
    text-align: left;
}
.preferenciasHora .form-inline{  
    display:inline;  
    display:initial;
}
@media (max-width: 991px){
    .professional-profile .professional-card{
        width:50%;
    }
    .professional-profile .featured-professional h2{
        margin-top: 50px!important;
    }
}
@media (max-width: 575px){
    .professional-profile .professional-card{
        width:100%;
    }
}
